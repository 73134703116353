// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
import Billing from "layouts/billing";
import SignIn from "layouts/authentication/sign-in";

// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Document from "examples/Icons/Document";
import { lazy } from "react";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Articles",
    key: "Articles",
    route: "/dashboard/Articles",
    icon: <Office size="12px" />,
    component: <Billing />,
    noCollapse: true,
  },
];
const authRoutes = [
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/dashboard",
    icon: <Document size="12px" />,
    component: <SignIn />,
    noCollapse: true,
  },
];

const Siteroutes = [
  {
    ind:{
      index:true,
    },
    component: lazy(() => import('./pages/Home')),
    exact: true
  },
  {
    path: '/blog',
    component: lazy(() => import('./pages/articles')),
    exact: true,
    ind:{}
  },
  {
    path: '/blog/:slug',
    component: lazy(() => import('./pages/deatilPost')),
    exact: true,
    ind:{}
  },

]

export { routes, authRoutes, Siteroutes };

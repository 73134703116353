/* eslint-disable */
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// Data
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import React, { useState } from "react";
import Card from "@mui/material/Card";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../const/const";
import { Spinner } from "reactstrap";
import SuiTypography from "../../components/SuiTypography";

function Dashboard() {
  /* eslint-disable */
  const [value, setValue] = useState("");
  const [titre, setTitre] = useState("");
  const [categories, setCategories] = useState(null);
  const [isGetcategories, setisGetcategories] = useState(null);
  const [category_id, setcategory_id] = useState(null);
  const [catState, setCatstate] = useState({
      category_name: "",
      category_desc: ""
  });
  const [isLog, setislog] = useState(false);
  const [isLogCat, setislogCat] = useState(false);
  /* eslint-disable */
  const modules = {
      toolbar: [
        [
          {
            'header': [1, 2, false]
          }
          ],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ]
    };
  /* eslint-disable */
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];


  const AddArticle=()=>{
    if (titre && value && category_id) {
      const dataState={
          "title": titre,
          "content": value,
          "user_id": localStorage.getItem("__uid__"),
          "category_id" :category_id,
          "reading_time": "12"
      }
      setislog(true)
      console.log("get",dataState)
      const options = {
        url: REACT_APP_BASE_URL + "/article/add" ,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
        data:dataState
      };
      axios(options)
        .then(response => {
          setislog(false)
          if (response.data.status==201){
            setTitre("")
            setValue("")
              setcategory_id(null)

          }else {
            alert(response?.data?.message,"Erreur")
          }
        })
        .catch(err => {
          setislog(false)
          console.log(err);
          alert("Problème de connexion","Erreur")
        });
    }else {
      alert("Remplisez tout le champs","Erreur")
    }
  }

  const AddCat=()=>{
    if (catState.category_desc && catState.category_name) {

      setislogCat(true)
      console.log("get")
      const options = {
        url: REACT_APP_BASE_URL + "/categorie/add" ,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
        data:catState
      };
      axios(options)
        .then(response => {
          setislogCat(false)
          if (response.data.status==201){
            setCatstate({
                category_name: "",
                category_desc: ""
            })

          }else {
            alert(response?.data?.message,"Erreur")
          }
        })
        .catch(err => {
            setislogCat(false)
          console.log(err);
          alert("Problème de connexion","Erreur")
        });
    }else {
      alert("Remplisez tout le champs","Erreur")
    }
  }
  const getCat=()=>{
      setisGetcategories(true)
      console.log("get")
      const options = {
        url: REACT_APP_BASE_URL + "/categories" ,
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      };
      axios(options)
        .then(response => {
            setisGetcategories(false)
          if (response.data.status==200){
              setCategories(response.data.data)
          }else {
            alert(response?.data?.message,"Erreur")
          }
        })
        .catch(err => {
            setisGetcategories(false)
          console.log(err);
          alert("Problème de connexion","Erreur")
        });
  }
useState(()=>{
    getCat()
},[])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>

          <SuiBox mb={3}>
              <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                      { /* eslint-disable */}
                      <Card style={{
                          padding:20
                      }}>
                          <Grid item xs={12} lg={12}>
                              <SuiBox pt={3} px={2} >
                                  <SuiTypography variant="h6" fontWeight="medium">
                                      Ajouter des categories
                                  </SuiTypography>
                              </SuiBox>
                          </Grid>
                          <SuiBox>
                              <input value={catState.category_name} className="col-12 mb-3" placeholder="category name" onChange={e=>{
                                  setCatstate({
                                      ...catState,
                                      category_name:e.target.value
                                  });
                              }} />
                          </SuiBox>
                          <SuiBox>
                              <textarea value={catState.category_desc} className="col-12 mb-3" placeholder="category description" onChange={e=>{
                                  setCatstate({
                                      ...catState,
                                      category_desc:e.target.value
                                  });
                              }} />
                          </SuiBox>
                          <SuiBox>

                          </SuiBox>
                          <SuiBox>
                              {
                                  !isLogCat
                                      ?
                                      <button className="mt-3 btn btn-success" onClick={AddCat}>Enregistrer</button>
                                      :
                                      <Spinner>
                                          Loading...
                                      </Spinner>
                              }
                          </SuiBox>
                      </Card>
                  </Grid>
              </Grid>
          </SuiBox>

        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              { /* eslint-disable */}
              <Card style={{
                padding:20
              }}>
                  <Grid item xs={12} lg={12}>
                  <SuiBox pt={3} px={2} >
                      <SuiTypography variant="h6" fontWeight="medium">
                          Ajouter article
                      </SuiTypography>
                  </SuiBox>
                  </Grid>
                <SuiBox>
                  <input value={titre} className="col-12 mb-3" placeholder="entrez le titre" onChange={e=>{
                    setTitre(e.target.value);
                  }} />
                </SuiBox>
                <SuiBox>
                  <select value={category_id} onChange={e=>setcategory_id(e.target.value)} className="col-12 mb-3" placeholder="entrez le titre" >
                      <option value={null}>Selectionner la categorie</option>
                      {
                          categories
                          ?
                              categories.map((item,index)=><option key={index} value={item.uid} >{item.category_name}</option>)
                          :
                          null
                      }
                  </select>
                </SuiBox>
        <SuiBox>
              <ReactQuill theme="snow" value={value} onChange={setValue}  modules={modules}
                          formats={formats} style={{
                            minHeight:40,
                            borderRadius:20,
                          }}
              />
        </SuiBox>
                <SuiBox>
                  {
                    !isLog
                      ?
                    <button className="mt-3 btn btn-success" onClick={AddArticle}>Enregistrer</button>
                      :
                      <Spinner>
                        Loading...
                      </Spinner>
                  }
                </SuiBox>
              </Card>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;

/* eslint-disable */
import { useState } from "react";

// react-router-dom components

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import { REACT_APP_BASE_URL } from "../../../const/const";
import axios from "axios";
import { Spinner } from "reactstrap";
import SweetAlert from 'sweetalert2-react';

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);
  const [email, setEmail] = useState(true);
  const [password, setPassword] = useState(true);
  const [isLog, setislog] = useState(false);
  const [alertOptions, setalertOptions] = useState({
    "show":false,
    "title":"",
    "message":""
  });
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const login=()=>{
    if (email && password) {
      const dataState={
        "email": email,
        "password": password,
        "source":"localhost"
      }
      setislog(true)
      console.log("get")
      const options = {
        url: REACT_APP_BASE_URL + "/user/login" ,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
        data:dataState
      };
      axios(options)
        .then(response => {
          setislog(false)
          if (response.data.status==200){
            setPassword("")
            setEmail("")
            localStorage.setItem("userToken",response.data?.Token)
            localStorage.setItem("__uid__",response.data?.data?.user?.uid)
            window.location.href="/dashboard"


          }else {
            alert(response?.data?.message,"Erreur")
          }
        })
        .catch(err => {
          setislog(false)
          if (err.response.data){
            alert(err.response.data.message)
          }else {
            alert("Erreur","Problème de connexion")
          }
        });
    }else {
      alert("Remplisez tout le champs","Erreur")
    }
  }

  return (
    <CoverLayout
      title="Welcome Admin"
      description="Enter your email and password to sign in"
      image={curved9}
    >
      <SuiBox component="form" role="form">
        <SuiBox mb={2}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SuiTypography>
          </SuiBox>
          <input type="email" placeholder="Email" className="form-control" onChange={e=>{
            setEmail(e.target.value)
          }}/>
        </SuiBox>
        <SuiBox mb={2}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SuiTypography>
          </SuiBox>
          <input  type="password" placeholder="Password" onChange={e=>{
            setPassword(e.target.value)
          }} className="form-control"/>
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          {
            !isLog
              ?
              <button className="mt-3 btn btn-success" onClick={login}>sign in</button>
              :
              <Spinner>
                Loading...
              </Spinner>
          }
        </SuiBox>
      </SuiBox>
    </CoverLayout>
  );
}

export default SignIn;

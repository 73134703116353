import React,{lazy} from "react";
import {Outlet} from "react-router-dom";
import { Spinner } from "reactstrap";
const Dashboarer=lazy(()=>import("./dashboarder"))
const Site=lazy(()=>import("./site"))
export const SiteLayout=(props)=>{
    return(
        <React.Suspense fallback={<></>}>
        <Site>
            <Outlet {...props}/>
        </Site>
        </React.Suspense>
    )
}
export const DashboarerLayout=(props)=>{
    return(
        <React.Suspense fallback={<Spinner>...Loader</Spinner>}>
        <Dashboarer>
            <Outlet {...props}/>
        </Dashboarer>
        </React.Suspense>
    )
}
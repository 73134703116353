/* eslint-disable */
import React,{useState,useEffect} from "react";
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Billing page components
import Bill from "layouts/billing/components/Bill";
import { REACT_APP_BASE_URL } from "../../../../const/const";
import axios from "axios";
import { Spinner } from "reactstrap";

function BillingInformation() {
  const [data, setdata] = useState(null);
  const [isLog, setislog] = useState(false);

  const getArticle=()=>{
    setislog(true)
      console.log("get")
      const options = {
        url: REACT_APP_BASE_URL + "/articles" ,
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        }
      };
      axios(options)
        .then(response => {
          setislog(false)
          if (response.data.status==200){
            //setdata(response.data.data)
            setdata(response.data?.data);
          }else {
            alert(response?.data?.message,"Erreur")
          }
        })
        .catch(err => {
          setislog(false)
          console.log(err);
          alert("Problème de connexion","Erreur")
        });
  }
  useEffect(()=>{
   getArticle()
  },[])

  return (
    <Card id="delete-account">
      <SuiBox pt={3} px={2}>
        <SuiTypography variant="h6" fontWeight="medium">
          Articles
        </SuiTypography>
      </SuiBox>
      <SuiBox pt={1} pb={2} px={2}>
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {!isLog
            ?
            data
            ?
           data.map((item,index)=> <Bill
             data={item}
             key={index}
           />)
              :
              null
            :
            <Spinner>
              Loading...
            </Spinner>
          }
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default BillingInformation;

/* eslint-disable */
import React,{useRef} from "react";
// prop-types is a library for typechecking of props

// @mui material components

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import parse from 'html-react-parser'
import ReactQuill from "react-quill";
import { renderIntoDocument } from "react-dom/test-utils";
import { renderToStaticNodeStream } from "react-dom/server";
function Bill({ data }) {
 const divData=useRef(null)
  return (
    <SuiBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor="grey-100"
      borderRadius="lg"
      p={3}
      mb={1}
      mt={2}
    >
      <SuiBox width="100%" display="flex" flexDirection="column">
        <SuiBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <SuiTypography variant="button" fontWeight="medium" textTransform="capitalize">
          <div>{data.title}</div>
          <div>{data.published_date}</div>
          </SuiTypography>
        </SuiBox>
        <SuiBox>
          <div dangerouslySetInnerHTML={ { __html: data.content} }></div>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

// Setting default values for the props of Bill


// Typechecking props for the Bill
export default Bill;

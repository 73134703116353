/* eslint-disable */
import  React from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// Soft UI Dashboard React routes
import { routes,authRoutes,Siteroutes } from "routes";
import {DashboarerLayout,SiteLayout} from "./layoutContent";
import { Spinner } from "reactstrap";


export default function App() {

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });


  return (
    <Routes>
      <Route path="/" element={<SiteLayout/>}>
        {
          Siteroutes.map((item,index)=>{
            const Component=item.component
            return(
              <Route  path={item.path} index={item.index} {...item.ind}   element={
              <React.Suspense fallback={<></>}>
                  {<Component />}
                </React.Suspense>
              } key={index} />
            )
          })
        }
      </Route>
      <Route path="/dashboard" element={<DashboarerLayout/>}>
          {
            localStorage.getItem("userToken")
              ?
              getRoutes(routes)
              :
              getRoutes(authRoutes)
          }
      </Route>
    </Routes>
  );
}
